// COMPONENTS
html, body{
  height: 100%
}
.appButton:hover {
  color: white !important;
  background-color: #d54100 !important;
}
.terms-Privacy-Text {
  color: "white" !important;
  font-size: "11px" !important;
  text-decoration: "none";
  margin-right: "10px";
  text-decoration-line: "underline";
}
.app-Header-Text {
  font-size: 42px;
  font-family: "Oswald";
  font-weight: 300;
}
.app-Sub-Header-Text {
  font-size: 17px;
  font-family: "museo-sans";
  font-weight: 700;
  letter-spacing: 1px;
}
.app-paragraph-text {
  font-size: 16px;
  font-family: "museo-sans";
  font-weight: 300;
}
.custom-switch {
  height: "50px" !important;
}
.custom-control-input {
  height: "100px" !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: red !important;
  background: #38b44a !important;
}
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.termsAndPrivacyContainer{
  margin-left: 30px;
  padding-bottom: 20px;
}
.inputButton:focus, .inputButton:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

// NAVBAR
.navbar {
  padding: 0px !important;
}
.account_toggle {
  position: absolute;
  // right: 76px;
  // top: 130px;
  z-index: 2;
  width: 250px;
  padding-bottom: 10px;
  margin-top: 75px;
  margin-right: 25px
}
.account:focus {
  outline: none;
}
.accountInfoDiv { 
display: flex;
  font-family: "museo-sans";
}
.accountInfoText {
  font-size: "16px";
  font-family: "museo-sans";
}
.accountDropdownContainer{
    background-color: #2366D4;
    display: flex;
    flex-direction: column !important;
    border-radius: 5px;
    // width: 250px;
    // margin-top: -35px
}
.accountDropdownContainer::before{
    content: "";
    position: absolute;
    top: -15px;
    left: 25px;
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: #2366D4 transparent;
    display: block;
    width: 0;
    z-index: 1;
    transform: rotate(180deg);
}
.invoice_toggle {
  position: absolute;
  // right: 76px;
  // top: 200px;
  z-index: 2;
  width: 250px;
  padding-bottom: 10px;
  margin-top: 55px;
}
.invoicesDropdownContainer{
  background-color: #2366D4;
  display: flex;
  flex-direction: column !important;
  border-radius: 5px;
  // width: 250px;
  // margin-top: -35px
}
.invoicesDropdownContainer::before{
  content: "";
  position: absolute;
  top: -15px;
  left: 35px;
  border-style: solid;
  border-width: 20px 20px 0;
  border-color: #2366D4 transparent;
  display: block;
  width: 0;
  z-index: 1;
  transform: rotate(180deg);
}
.service_toggle {
  position: absolute;
  // right: 76px;
  // top: 200px;
  z-index: 2;
  width: 250px;
  padding-bottom: 10px;
  margin-top: 55px;
  margin-left: 150px;
}
.servicesDropdownContainer{
  background-color: #2366D4;
  display: flex;
  flex-direction: column !important;
  border-radius: 5px;
  // width: 250px;
  // margin-top: -35px
}
.servicesDropdownContainer::before{
  content: "";
  position: absolute;
  top: -15px;
  left: 35px;
  border-style: solid;
  border-width: 20px 20px 0;
  border-color: #2366D4 transparent;
  display: block;
  width: 0;
  z-index: 1;
  transform: rotate(180deg);
}
.navDropdownItem{
  color: white !important;
  font-size: 13px !important;
  font-family: "museo-sans" !important;
  font-weight: 700 !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: 10px
}
.navDropdownItem:hover{
text-decoration: underline;
}
.accountDropdownSpacer{
  border-bottom: white .5px solid;
  margin-left: 15px;
  margin-right: 15px,
}
.waveNavbarRightHalf{
    padding-top: 8px;
    margin-right: 7%;
    margin-left: 15%;
    margin-bottom: 20px,
}
.navTogleText{
  white-space: nowrap;
}

// MOBILE NAVBAR
.hamburgerMenuIcon {
  color: #1a4ea2;
}
.hamburgerMenuIcon:hover {
  color: white;
  background-color: #d54100 !important;
}
.mobileNavbarItem {
  color: "white";
  font-size: "18px";
  text-decoration: "none";
  margin-left: "10px";
  margin-top: "15px";
}


// LOGIN
// .loginForm {
//   margin-top: 75px;
// }
// .forgotPassword {
//   margin-bottom: 75px;
// }
// .TACLoginStatementDiv {
//   margin-bottom: 40px;
// }
.TACLoginStatement {
  text-align: center;
  font-size: 15px;
  color: "#4985B5";
  font-family: "museo-sans-condensed";
  font-weight: 700;
}
.loginImageDesktop{
  display: none;
}
.loginLink{
  text-decoration: none;
}
.loginLink:hover{
  text-decoration: underline;
}
.loginImageMobile{
  display: flex;
  flex-direction: column;
  background-image: url(../assets/imgs/loginMobileBGImage.jpg) !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-repeat: round;
    background-size: cover;
}


// DASHBOARD
.dashboardBackground {
  background-image: linear-gradient(#007ac8, #002d81);
  background-size: "cover" !important;
  background-repeat: "round" !important;
  // height: 100% !important;
}
.payByText, .reviewInvoicesButton {
  text-align: center;
}
.totalBalanceDue{
  justify-content: center;
  margin-top: -15px;
  margin-bottom: 0px;
}
.payNowButtonDiv{
  // margin-left: 25px;
  display: flex;
  justify-content: center;
}

.quickLinksDiv {
  flex-direction: row;
}
.quickLinksHeader {
  margin-top: 70px;
}
.quickLinksLeftDiv {
  width: 50%;
  padding-left: 30px;
}
.quickLinksRightDiv {
  width: 50%;
  padding-left: 15px;
}
.quickLinksItemContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.quickLinkItemText{
  text-align: left;
  color: #FFE160;
  width: 100%;
  font-family: "museo-sans";
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0px;
}
.whiteUnderlineHover:hover{
  text-decoration: underline !important;
  text-decoration-color: #FFF !important;
}
.blackUnderlineHover:hover{
  text-decoration: underline !important;
  text-decoration-color: #000 !important;
}
.goldUnderlineHover:hover{
  text-decoration: underline !important;
  text-decoration-color: #FFE160 !important;
}
.blueUnderlineHover:hover{
  text-decoration: underline !important;
  text-decoration-color: #1A4EA2 !important;
}
.redUnderlineHover:hover{
  text-decoration: underline !important;
  text-decoration-color:#d54100 !important;
}
.redUnderlineHover .addPaymentIcon{
  color: white;
  // background-color: #D54100;
  border: none;
}
.addPaymentIcon:hover {
  background-color: #D54100;
  color: white !important;
}
.cardHover:hover{
  box-shadow: 15px 15px 50px #0000001A !important
}
.custom-modal {
  border-radius: 20px !important;
  margin: 20px;
}
.custom-modal-dashboard {
  border-radius: 40px !important;
  margin: 25px;
  padding-right: 20px;
  padding-left: 20px;
}
.appDashboardMessageContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // border: 2px solid #FFE160;
    // border-radius: 25px;
    // padding: 25px 35px;
    width: 325px;
    margin-bottom: 40px;
}
.appDashboardMessage{
  position: absolute;
  width: 250px;
  margin-left: 30px;
  margin-bottom: 30px;
}
.modalDashboard{
  margin-right: 20px;
}
.dashboardModalText{
  text-align: left;
  color: #1A4EA2;
  width: 100%;
  font-family: "museo-sans";
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 0px;
  align-items: center;
  display: flex;
}


// PAYMENTS
.paymentsLoadingSpinnerContainer{
  margin-top: 65px;
  margin-bottom: 70%;
  display: flex;
  justify-content: center;
}
.paymentsScreenContainer{
  margin-top: 25px;
  margin-left: 30px;
  margin-right: 20px;
}
// .selectPaymentContainer{
//   max-width: 685px;
// }
.radio:hover{
  color: "blue" !important
}
.closePaymentModalButton:hover{
  background-color: #E9E9E9;
  border-radius: 25px;
}
.AppSelectPaymentMethod{
  box-shadow: 1px 1px 6px #00000026;
}


// PAYMENT CONFIRMATION
.iconAndHeaderContainer{
display: flex;
flex-direction: column;
text-align: center;
align-items: center;
margin-top: 75px;
margin-bottom: 40px;
}
.paymentConfirmationServicesContainer{
  margin-top: 40px;
  // max-width: 1250px;
}
.paymentConfirmationServiceItem{
  font-family: "Oswald";
  font-size: 24px;
  border-top: .5px solid #0000004D;
  padding-top: 10px;
}
.paymentConfirmationTotalContainer{
  border-top: 4px solid #0000004D;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paymentConfirmationTotalText{
  font-family: "Oswald";
  font-size: 18px;
  padding-top: 10px;
}
.paymentConfirmationTotalNum{
  font-family: "Oswald";
  font-size: 24px;
  padding-top: 10px;
}
.returnHomeLinkPaymentConfirmation{
  color: #D54100;
  width: 100%;
  display: flex;
  justify-content: center;
  // margin-top: 50px;
  font-family: "museo-sans-condensed";
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 1.5px;
  margin-top: 50px;
  margin-bottom: 80px;
}

.appAccordianName{
  font-family: "Oswald";
  font-size: 24px;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 155px;
  padding-bottom: 5px
}
.amountHeaderPaymentsContainer{
  background: #FFFFDD;
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 100px;
  justify-content: center;
  min-height: 85px
}
.amountHeaderPayments{
  display: none;
  margin-right: 15px;
}
.yellowItemPriceBar{
  background: #FFFFDD;
  // height: 100%;
  display: flex;
  align-items: center;
  min-width: 90px;
  justify-content: center;
  // min-height: 50px
}
.yellowItemMemo{
  background: #FFFFDD;
  display: flex;
  align-items: center;
  min-width: 90px;
  justify-content: center;
}
.yellowPriceBar{
  background: #FFFFDD;
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 90px;
  justify-content: center;
  min-height: 85px
}
.priceText{
  font-family: "Oswald";
  font-size: 24px;
  text-align: right;
  font-weight: 100;
  margin-bottom: 0px;
}
.subPriceText{
  font-family: "Oswald";
  font-size: 18px;
  text-align: right;
  font-weight: 100;
  margin-bottom: 0px;
}
.prevInvoicePriceText{
  font-family: "Oswald";
  font-size: 20px;
  text-align: right;
  font-weight: 100;
  margin-bottom: 0px;
}

// ADD A NEW PAYMENT
.addPaymentMethodSubHeader{
  color: #727272;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 50;
  max-width: 90%
}
.addPaymentMethodConfirmationButtonsContainer{
  display: flex;
  flex-direction: column;
}
.addPaymentDropdownToggle{
  width: 100%;
  height: 60px;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}


//  ACCOUNT
.contentContainer{
  margin: 0px 10% 10% 10%;
  max-width: 1250px;
  justify-content: center;
}
.myAccountHeader{
  margin: 40px 0px;
}
.accountInfoCard{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}
.accountBlueCard{
  background: #1A4EA2;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%
}
.listedAccountInfoDiv{
  box-shadow: 0px 0px 20px #0000001A;
  width: 100%;
  padding: 0px 35px;
}
.accountInfoHeader{
  font-size: 16px;
  font-weight: 700;
}
.accountInfoText{
  font-size: 16px;
  font-weight: 300;
}
.accountInfoSeparator{
  display: block;
  border: 1px solid #E9E9E9;
  margin-bottom: 20px;
}
.accountName{
  margin-top: 40px;
}
.termsAndPrivacyContainerPayments{
  margin-left: 40px;
  position: relative;
  bottom: 0;
  right: 10;
  padding-bottom: 20px;
}



// EDIT ACCOUNT
.editAccountContentContainer{
  margin-left: 10%;
  max-width: 90%;
  // width: 800px;
}
.editAccountSubHeader{
  color: #727272;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 50;
  border-bottom: 1px solid #E9E9E9;
  max-width: 90%
}
.editAccountFormLong{
  width: 100% !important;
}
.editAccountInfoText{
  width: 90%
}

// CONTACT
.contactContentContainer{
  margin-left: 10%;
  margin-right: 10%;
}
.contactSubHeader{
  color: #939393;
  font-weight: 700;
  font-size: 20px;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 50;
  // border-bottom: 1px solid #E9E9E9;
  max-width: 90%
}
.contactRightContainer{
  margin-left: 10%;
}
.contactDropdownToggle{
  width: 90%;
  height: 60px;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

// Edit
.form_item{
  width: 90% !important;
}

.preferredMethodCellPhoneNum{
  width: 80%;
  // margin-left: 45px;
}

// MY SERVICES
.myServicesContentContainer{
  margin-left: 10%;
  margin-right: 10%;
}
.servicesDialContainer{
  width: 300px;
  height: 65px;
  border-radius: 35px;
  box-shadow: 0px 0px 20px #0000001A;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.servicesDialDay{
  margin-bottom: 0px;
  color: #0000004D;
  width: 13%;
  text-align: center;
  font-family: "Oswald";
  font-size: 28px;
}
.serviceReadingsChart{
  width: 100%;
  margin-bottom: 35px;
}
.serviceReadingsItem{
  // width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  height: 25px
}
.servicePerformedItem{
  // width: 45%;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  height: 25px
}
.servicePerformedCheck{
  font-weight: bold;
  margin-right: 10px;
}

// LOGOUT
.logoutTitle{
  font-family: "Oswald";
  font-size: 75px;
  color: #727272;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 50px;
}

// 404
.routeNotFoundContainer{
  margin: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 150px);
}
.returnHomeLink{
  color: #D54100;
  width: 100%;
  display: flex;
  justify-content: center;
  // margin-top: 50px;
  font-family: "museo-sans-condensed";
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 1.5px;
  margin-top: 50px;
}


// TERMS AND CONDITIONS
.termsAndConditionsContainer{
  // margin: 40px 40px;
  margin: 50px 10% 10% 10%;
  max-width: 800px;
}
.mainNavbar{
  display: none !important;
}

// MID QUERY
@media screen and (min-width: 600px) {
  .appAccordianName{
    width: 350px;
    padding-bottom: 0px;
  }
  .serviceReadingsChart{
    display: flex;
    flex-direction: row;
    width: 100%
  }
  .serviceReadingsChartLeftContainer{
    width: 50%
  }
  .serviceReadingsItem, .servicePerformedItem{
    width: 90%;
  }
  .loginImageDesktop{
    display: flex;
    background-image: url(../assets/imgs/loginDesktopBGImage.jpg) !important;
      justify-content: center;
      align-items: center;
      height: 100vh;
      width: 100vw;
      background-repeat: no-repeat;
      background-size: cover;
  }
  
  .loginImage{
    width: 90vw;
                margin-left: 10vw
  }
  .loginImageMobile{
    display: none
  }
}

// LARGER THAN PHONE QUERY
@media screen and (min-width: 1100px) {
  // MY ACCOUNT
  .contentContainer{
    margin: 0px 10% 10% 10%;
  }
  .preferredMethodCellPhoneNum{
    width: 90%;
    // margin-left: 50px;
  }
  // COMPONENTS
  .app-Header-Text {
    font-size: 50px;
  }
  .termsAndPrivacyContainer{
    margin-left: 150px;
    position: fixed;
    bottom: 0;
  }
  .mobileNavbar{
    display: none;
  }
  .mainNavbar{
    display: flex !important;
  }
  .waveNavbarLogo{
    margin: 0px 10% !important;
  } 
  .loginImage{
    width: 90vw;
                margin-left: 10vw
  }
  

  // LOGIN
  .login-Background {
    background-image: url(../assets/imgs/loginBackgroundLarge.png) !important;
    width: "60%";
    justify-content: "center";
  }
  .loginForm {
    margin-top: 0px;
  }
  .forgotPassword {
    margin-bottom: 0px;
  }
  .TACLoginStatement {
    font-size: 17px;
  }
  .TACLoginStatementDiv {
    margin-bottom: 0px;
  }

  // LOGOUT
.logoutTitle{
  font-family: "Oswald";
  font-size: 135px;
  color: #727272;
  font-weight: 600;
  margin-left: 20px;
}

  // DASHBOARD
  .dashboardBackground {
    background-image: url("../assets/imgs/loginBackgroundLarge.png");
    background-size: 100%;
    position: absolute;
    top: 0;
    height: 100% !important;
  }
  .dashboardContentDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 15%;
    max-width: 1250px;
  }
  .payByText, .reviewInvoicesButton {
    text-align: left !important;
  }
  .totalBalanceDue{
    justify-content: flex-start;
    margin-top: 0px;
  }
  .payNowButtonDiv{
    margin-left: 0px;
  }
  .quickLinksDiv {
    flex-direction: column;
  }
  .quickLinksLine{
    display: none;
  }
  .quickLinksHeader {
    margin-top: 0px;
    margin-left: 35px;
  }
  .quickLinksLeftDiv,
  .quickLinksRightDiv {
    width: 100%;
    padding-left: 0px;
  }
  .quickLinksItemContainer {
    align-items: center;
  }
  .quickLinkItemText{
    margin-bottom: 0px;
  }
  .appDashboardMessageContainer{
    width: 375px;
  }
  .appDashboardMessage{
    width: 350px;
    margin-bottom: 50px;
  }
  
  // PAYMENTS
  .paymentsScreenContainer{
    margin-top: 25px;
    margin-left: 10%;
    // margin-right: 300px;
  }
  // .selectPaymentContainer{
  //   max-width: 950px;
  // }
  .termsAndPrivacyContainerPayments{
    margin-left: 130px;
    padding-bottom: 20px;
  }
  .appAccordianName{
    width: inherit
  }
  .paymentsLoadingSpinnerContainer{
    display: flex;
    justify-content: flex-start;
    margin-left: 350px;
  }
  .amountHeaderPayments{
    display: block;
    
  }
  .yellowPriceBar{
    min-width: 175px;
    justify-content: flex-end;
  }
  .yellowItemPriceBar{
    background: #FFFFDD;
    height: 100%;
    display: flex;
    align-items: center;
    min-width: 175px;
    justify-content: flex-end;
    min-height: 30px;
    padding-bottom: 5px;
  }
  .yellowItemMemo{
    background: #FFFFDD;
    height: auto;
    display: flex;
    align-items: center;
    min-width: 175px;
    justify-content: flex-end;
    min-height: inherit;
    padding-bottom: 5px;
  }
  .priceText{
    margin-right: 15px
  }
  .subPriceText{
    margin-right: 15px
  }


  // PAYMENT CONFIRMATION
  .returnHomeLinkPaymentConfirmation{
    margin-bottom: 100px;
  }

  // ADD PAYMENT
  .addPaymentMethodInputContainer{
    width: 50%
  }
  .addPaymentMethodConfirmationButtonsContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

  // Edit
.form_item{
  width: 100% !important;
}


  // EDIT ACCOUNT
  .editAccountContentContainer{
    max-width: 80%;
  }
  .editAccountSubHeader{
    max-width: 100%;
  }
  .fullLengthForm{
    width: "100%" !important;
  }
  .editAccountInfoText{
    width: 100%
  }

  // CONTACT
  .contactContentContainer{
    margin-left: 10%;
    max-width: 800px;
    margin-right: 65px;
  }
  .contactRightContainer{
    margin-top: 125px;
    margin-left: 0px;
  }
  .contactDropdownToggle{
    width: 100%
  }

  // MY SERVICES
  .myServicesContentContainer{
    margin-left: 10%;
    width: 800px;
    margin-right: 65px;
  }
  .myServicesRightContainer{
    margin-top: 125px;
    margin-left: 0px;
  }
  .servicesDialContainer{
    width:350px
  }

  // 404
  .routeNotFoundContainer{
    margin: 0px 10% 10% 10%;
    height: calc(100% - 250px);
  }
  
}

// 1200 QUERY
@media screen and (min-width: 1200px) {
  .contactFlexContainer{
    display: flex;
  }
  .accountInfoCard{
    flex-direction: row-reverse;
    display: flex;
    justify-content: center;
  }
  .accountBlueCard{
    min-height: 275px;
    width: 400px
  }
  .listedAccountInfoDiv{
    display: flex;
    height: fit-content;
    justify-content: space-between;
    padding: 30px;
    min-height: 275px;
    // max-width: 750px;
  }
  .accountInfoText{
    margin: 0px;
    // white-space: nowrap;
  }
  .accountName{
    margin-top: 0px;
  }
  .bottomContainer{
    display: flex;
    justify-content: center;
  }
  .bottomLeftContainer{
    width: 60%;
    padding: 20px 50px 20px 0px,
  }
  .bottomRightContainer{
    width: 40%;
    padding: 20px 50px,
  }
  .accountCardInfoDiv{
    padding: 0px 20px;
    width: 33%;
    overflow-wrap: break-word;
  }

  .loginImage{
    width: 75vw;
    margin-left: 25vw
  }
  
  

  // EDIT ACCOUNT 
  .editAccountContentContainer{
    margin-left: 10%;
    max-width: 800px;
    // width: 800px;
  }
  .editAccountFlexInputsContainer{
    display: flex;
  }

  // MY SERVICES
  .myServicesFlexContainer{
    display: flex;
  }

  
}

@media screen and (min-width: 1260px) {
.waveNavbarRightHalf{
  margin-right: 15%;
  margin-left: 10%
}
}

// XL -HEIGHT- QUERY
@media screen and (min-height: 930px) {
  .dashboardBackground{
    height: 100%;
  }
}