/* body {
    display: flex;
    justify-content: center;
    background-color: rgb(248 250 252);
} */
#terminal {
    background-color: white;
    width: "100%";
    border-width: 1px;
    filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
}
#loading-msg {
    padding: 1rem 1rem 2rem 1rem;
    color: rgb(71 85 105);
    animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
}
.ut-terminal-panel{
    width: 100%
}
.ut-input{
    width: 100%;
    height: 60px;
    border: 1px solid rgb(213, 213, 213);
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 10px;
    font-family: "museo-sans";
}
.ut-combobox-input{
    width: 80%;
    height: 60px;
    border: 1px solid rgb(213, 213, 213);
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 10px;
    font-family: "museo-sans";
}
.ut-button{
    color: rgb(26, 78, 162);
    background-color: rgb(255, 225, 96);
    border: none;
    border-radius: 25px;
    width: fit-content;
    font-size: 20px;
    font-weight: 700;
    font-family: "museo-sans";
    letter-spacing: 2.5px;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-right: 40px;
    padding-left: 40px;
    height: 75px;
}
/* .ut-label, .p-FieldLabel.Label.Label--empty{
    font-family: "museo-sans";
    font-size: 18px;
    font-weight: "600";
    color: rgba(var(--bs-body-color-rgb),.65)
} */
svg[data-v-7c8405db]{
    width: 25px;
    margin-left: 20px;
}
svg[viewBox="0 0 24 24"]{
    width: 40px;
    color: #1A4EA2
}
.ut-result-panel{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.resultContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.resultContainer .text-center.text-slate-800 {
    font-family: "museo-sans";
    font-size: 30px;
    margin-bottom: 100px;
}
.ut-icon ut-icon_green ut-icon_large{
    display: flex;
    justify-content: center;
}
.p-BankView--text{
display: none !important;
}
@media screen and (min-width: 1100px) { 
    .ut-terminal-panel{
        width: 70%
    }
    .ut-combobox-input {
        width: 92%;
    }
    .ut-button{
        height: 50px;
    }
    .p-BankView--text{
    display: none !important;
    }
}
